import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { navigate } from 'gatsby';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { IBankSession } from '../types/bank-session';
import IFrame from './bank-frame';
import { Header, StepContainer } from './SignUpSteps/components';

interface IBankDataProps {
  bankSession: IBankSession | undefined;
  errorMessage: string;
  errorType?: string;
  getBankSession: () => void;
  submitUserData: () => void;
  tokenValidationLoader: boolean;
}

const COMPLETE_PAGE_URL: string = process.env.GATSBY_BANKDATA_COMPLETE_PAGE_URL!;
const FAQS_PAGE_URL: string = process.env.GATSBY_FAQS_PAGE_URL!;

export default ({
  bankSession,
  errorMessage,
  errorType,
  getBankSession,
  submitUserData,
  tokenValidationLoader,
}: IBankDataProps) => {
  const [bankLoginErrorMessage, setBankLoginErrorMessage] = useState('');
  const [userSubmitErrorMessage, setUserSubmitErrorMessage] = useState('');
  const [bankSessionErrorMessage, setBankSessionErrorMessage] = useState(errorMessage);

  useEffect(() => {
    setBankSessionErrorMessage(errorMessage);
  }, [errorMessage]);

  const handleSubmitUserData = async () => {
    try {
      // submit user data on complete
      await submitUserData();
      await navigate(COMPLETE_PAGE_URL);
    } catch (error) {
      const title = error instanceof Error ? error.message : '';
      setUserSubmitErrorMessage(title);
    }
  };

  useEffect(() => {
    const handleEvent = async (event: MessageEvent) => {
      if (event.origin !== get(bankSession, 'data.attributes.origin', '')) return;
      if (event.data) {
        const { status, status_message } = JSON.parse(event.data);
        if (status === 'COMPLETE') {
          await handleSubmitUserData();
        } else if (status === 'INVALID TOKEN') {
          // iframe timeout, request new iframe url
          getBankSession();
        } else {
          // all other errors, display error dialogue
          setBankLoginErrorMessage(status_message);
        }
      }
    };
    window.removeEventListener('message', handleEvent, false);
    window.addEventListener('message', handleEvent, false);
    return () => {
      window.removeEventListener('message', handleEvent, false);
    };
  }, [bankSession]);

  const handleRetryClick = () => {
    // clear previous error message
    setBankLoginErrorMessage('');
    // re-fetch iframe universal
    getBankSession();
  };

  const handleUserSubmitRetryClick = () => {
    // clear previous error message
    setUserSubmitErrorMessage('');
    // re-submit user data
    void handleSubmitUserData();
  };

  const onTryAgain = () => {
    void getBankSession();
  };

  const onClickViewFAQ = () => {
    void navigate(FAQS_PAGE_URL);
  };

  return (
    <StepContainer>
      <Header styles={{ textAlign: 'center' }}>Access Your Pay in Minutes</Header>
      <Typography marginBottom={2} textAlign='center'>
        We need your most recent bank statements to verify your income and expenses.
        <br />
        The easiest way is to retrieve them online using the form below -{' '}
        <strong>this is secure and fast</strong>.
        <br />
        By proceeding, you also agree to the <a href='https://presspay.com.au/ddrsa/' target='_blank'>Direct Debit Service Agreement.</a>
      </Typography>
      {tokenValidationLoader && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 4,
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      )}
      {bankSession && <IFrame src={bankSession?.data?.attributes?.url} />}
      <Dialog title="Oh Uh!" open={!!bankLoginErrorMessage}>
        <DialogTitle>Oh Uh!</DialogTitle>
        <DialogContent>{bankLoginErrorMessage}</DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" color="secondary" onClick={handleRetryClick}>
            Try again
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog title="Oh Uh!" open={!!userSubmitErrorMessage}>
        <DialogTitle>Oh Uh!</DialogTitle>
        <DialogContent>
          <Typography>
            Unfortunately an issue is preventing us from completing your bank statement submission.
          </Typography>
          <Typography>Please see our FAQ for the following error code:</Typography>
          <Typography sx={{ whiteSpace: 'pre-wrap', marginTop: 2 }} color="error">
            {userSubmitErrorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickViewFAQ}>View FAQ</Button>
          <Button
            autoFocus
            variant="contained"
            color="secondary"
            onClick={handleUserSubmitRetryClick}
          >
            Try Again
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog title="Oh Uh!" open={!!bankSessionErrorMessage}>
        <DialogTitle>Oh Uh!</DialogTitle>
        <DialogContent>
          <Typography>
            Unfortunately an issue is preventing us from completing your bank statement submission.
          </Typography>
          <Typography>Please see our FAQ for the following error code:</Typography>
          <Typography sx={{ whiteSpace: 'pre-wrap', marginTop: 2 }} color="error">
            {bankSessionErrorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickViewFAQ}>View FAQ</Button>
          {errorType !== 'INVALID_TOKEN' && (
            <Button autoFocus variant="contained" color="secondary" onClick={onTryAgain}>
              Try again
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </StepContainer>
  );
};
