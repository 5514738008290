import { PageProps } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import BankComponent from '../components/BankDataComponent';
import { callApi } from '../helpers/api';
import { IBankSession } from '../types/bank-session';

const TOKEN_ERROR_MESSAGES = [
  `"x-bank-data-token" must be a valid GUID`,
  `Invalid bank data token`,
];

function BankData(props: PageProps) {
  const [tokenValidationLoader, setTokenValidationLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorType, setErrorType] = useState<string>();
  const [bankSession, setBankSession] = useState<undefined | IBankSession>(undefined);
  const token = useMemo(() => {
    const params = new URLSearchParams(props.location.search.substring(1));
    return params.get('token');
  }, []);
  const getBankSession = async () => {
    setErrorMessage('');
    setTokenValidationLoader(true);
    try {
      const res = await callApi('/bank-data', 'get', {}, { 'x-bank-data-token': token });
      setTokenValidationLoader(false);
      setBankSession(res.data);
    } catch (error) {
      // @ts-ignore
      const code = error.response.data?.errors?.[0]?.code;
      // @ts-ignore
      const title = error.response.data?.errors?.[0]?.title;
      if (code === 'ValidationError' && TOKEN_ERROR_MESSAGES.includes(title)) {
        setErrorType('INVALID_TOKEN');
      } else {
        setErrorType('GET_BANK_SESSION_FAILED');
      }
      setTokenValidationLoader(false);
      setErrorMessage(title);
    }
  };
  const submitUserData = async () => {
    try {
      if (!token) {
        // TODO: resolve correct error axios handling
        throw new Error('');
      }
      const res = await callApi('/bank-data', 'post', {}, { 'x-bank-data-token': token });
      setBankSession(res.data);
    } catch (error) {
      // TODO: resolve correct error axios handling
      // @ts-ignore
      const title = error.response.data?.errors?.[0]?.title;
      if (title) {
        throw new Error(title);
      }
      throw new Error('Ops. An unknown error has occurred. Please try again.');
    }
  };

  useEffect(() => {
    void getBankSession();
  }, []);

  return (
    <BankComponent
      tokenValidationLoader={tokenValidationLoader}
      submitUserData={submitUserData}
      bankSession={bankSession}
      getBankSession={getBankSession}
      errorMessage={errorMessage}
      errorType={errorType}
    />
  );
}

export default BankData;
